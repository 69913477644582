import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import '../../scss/FooterMobile.scss';
import { useTranslation } from "react-i18next";

function FooterMobile( {index} ) {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');
  const [getLang, setLan] = React.useState("it");


  useEffect(() => {
    if( i18n.language != 'it-IT' ) {
      setLan("en");
    }

  }, []);

  return (
    <div className="FooterMobile">
      <div>
        <Link to="/dashboard" className={index == 1 ? 'active' : ''}>
          <i className="fi fi-rr-home"></i>
        </Link>
      </div>
      <div>
        <Link to="/all-teams" className={index == 2 ? 'active' : ''}>
          <i className="fi fi-rr-chart-line-up"></i>
        </Link>
      </div>
      <div>
        <Link to="/classification" className={index == 3 ? 'active' : ''}>
          <i className="fi fi-rr-trophy"></i>
        </Link>
      </div>
      <div>
        <Link to="/calendar" className={index == 4 ? 'active' : ''}>
          <i className="fi fi-rr-calendar"></i>
        </Link>
      </div>
      <div>
        <Link to="/leagues" className={index == 5 ? 'active' : ''}>
          <i className="fi fi-rr-badge"></i>
        </Link>
      </div>
      <div>
        <Link to="/logout" className={index == 6 ? 'active' : ''}>
          <i className="fi fi-rr-sign-out-alt"></i>
        </Link>
      </div>
    </div>
  );
}

export default FooterMobile;
