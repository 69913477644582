import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Header from '../partials/header/Header';
import Footer from '../partials/Footer';
import FooterMobile from '../partials/FooterMobile';
import Error from '../partials/Error';
import { useTranslation } from "react-i18next";
import { IsMobile } from '../../services/IsMobile';
import Loader from '../partials/Loader';

function AllTeams() {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');

  const [myInfo, setMyInfo] = React.useState([]);

  const [allTeamState, setAllTeamState] = React.useState([]);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [appError, setStateError] = React.useState("");

  const [myPos, setMyPos] = React.useState({
                              general: 0
                            });

  const [myStop, setMyStop] =  React.useState(true);

  const [ mobile ] = React.useState(IsMobile());
  
  const [loader, setLoader] =  React.useState(true);
      
  const [height, setHeight] =  React.useState("");

  useEffect(() => {

    window.scrollTo(0, 0);

    if( mobile ) {
      document.body.classList = "overflow";
      var rect = document.getElementsByClassName("FooterMobile")[0].getBoundingClientRect();

      setHeight(rect.bottom)
    }

    if( !localStorage.getItem("fm_info") ) {
      window.location.href = "/";
    }

    if( myStop ) {
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        i18n.changeLanguage('it-IT');
      }
      else {
        i18n.changeLanguage('en-US');
      }
      setMyStop(false);
    }

    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    setMyInfo(userInfo);

    let params = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id
    }

    if( myCtrl ) {

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-teams', params )
          .then(response => {
            setLoader(false);
            if(response.data.error.status === false) {
              // OK
              let ctrlAllTeams = response.data.rls.allTeams;
              var byPoints = ctrlAllTeams.slice(0);
              byPoints.sort(function(a,b) {
                  var x = a.points;
                  var y = b.points;
                  return y < x ? -1 : y > x ? 1 : 0;
              });

              setAllTeamState(byPoints);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });
    }

    setMyCtrl(false);

  }, [myCtrl]);

  function onChangeMyError( error ) {
    setStateError(error);
  }

  return (
    <div className="App">
      <Header title={t('pages.teams')} index="2" />
      <div className={ mobile ? 'content-card' : 'content-card forDesktop' } style={loader ? { display: 'none' } : { display: 'block' } }>
        <div className="card card-2">
          <div className="card-header">
            {t('dashboard.cardTwo')} - <span className="myPos">Sei in P.{ myPos.general }</span>
          </div>
          <div className="card-body allUsers" style={ {maxHeight: "calc( " + height + "px - 400px)"} }>
            {allTeamState && allTeamState.length ? (
              allTeamState.map(function(item, i) {
                if( item.nickname == myInfo.nickname && myPos.general == 0 ) {
                  setMyPos({...myPos, general: i + 1});
                }
                return (
                  <div className={item.nickname == myInfo.nickname ? "contentValue iam" : "contentValue"} key={ i }>
                    <div className="contentAllTeam">
                      <div><span className="position">#{ i + 1 }</span></div>
                      <div>{ item.nickname }</div>
                      <div><span className="viewPoins">{ item.points }pt</span></div>
                    </div>
                      
                    <br />
                    <small><i>{ item.drivers.replace('-', ' - ') }</i></small>
                  </div>
                );
               })
            ) : (
              <div>{t('dashboard.noResultAll')}</div>
            )}
          </div>
        </div>
      </div>
      <Loader show={loader} />
      <Error params={appError} onChangeError={onChangeMyError} />
      { !mobile && (<Footer />) }
      { mobile && (<FooterMobile index="2" />) }
    </div>
  );
}

export default AllTeams;
