import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Header from './partials/header/Header';
import Footer from './partials/Footer';
import FooterMobile from './partials/FooterMobile';
import Error from './partials/Error';
import ModalCreateTeam from './partials/ModalCreateTeam';
import ModalSocial from './partials/ModalSocial';
import { useTranslation } from "react-i18next";
import { IsMobile } from '../services/IsMobile';

import Loader from './partials/Loader';

function Dashboard() {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');

  const [myInfo, setMyInfo] = React.useState([]);

  const [myTeamState, setMyTeamState] = React.useState([]);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [myModalCreateTeam, setMyModalCreateTeam] = React.useState('none');

  const [appError, setStateError] = React.useState("");

  const [myStop, setMyStop] =  React.useState(true);

  const [loader, setLoader] =  React.useState(true);

  const [ mobile ] = React.useState(IsMobile());

  useEffect(() => {

    window.scrollTo(0, 0);

    if( mobile ) {
      document.body.classList = "overflow";
    }

    if( !localStorage.getItem("fm_info") ) {
      window.location.href = "/";
    }

    if( myStop ) {
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        i18n.changeLanguage('it-IT');
      }
      else {
        i18n.changeLanguage('en-US');
      }
      setMyStop(false);
    }

    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    setMyInfo(userInfo);

    let params = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id
    }

    if( myCtrl ) {
      axios.post(process.env.REACT_APP_API_URL + '/dashboard/my-teams', params )
          .then(response => {
            setLoader(false);
            if(response.data.error.status === false) {
              // OK
              setMyTeamState(response.data.rls.drivers);
              // setBonus(response.data.rls.bonus);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });

    }

    setMyCtrl(false);

  }, [myCtrl]);

  function onChangeMyError( error ) {
    setStateError(error);
  }

  function createMyTeam(e, ctrl) {
    setMyModalCreateTeam(ctrl);
    if( ctrl == 'block' ) {
      document.getElementsByTagName("body")[0].classList.add("overflow");
    }
    else {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
    }
  };

  return (
    <div className="App">
      <Header title="DASHBOARD" index="1" />
      <div className={ mobile ? 'content-card' : 'content-card forDesktop' } style={loader ? { display: 'none' } : { display: 'block' } }>
        <div className="card card-2">
          <div className="card-header">
            {t('dashboard.cardOne')} - { myInfo.nickname }
          </div>
          <div className="card-body">
            {myTeamState && myTeamState.length ? (
              myTeamState.map(function(item, i) {
                return (
                  <div className="contentValue contentMyTeam" key={ i }>
                    <div><img src={ item.link } width="50" style={{color: item.color}} /></div>
                    <div>{ item.driver_name }</div>
                    <div><span className="viewPoins">{ item.points }pt</span></div>
                  </div>
                );
               })
            ) : (
              <div>
                {t('dashboard.noResult')}
                <br /><br />
                <div className="content-button">
                  <button type="button" className="button" onClick={(e)=> {createMyTeam(e, 'block')}}>
                    {t('dashboard.createTeam')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader show={loader} />
      <Error params={appError} onChangeError={onChangeMyError} />
      { !mobile && (<Footer />) }
      { mobile && (<FooterMobile index="1" />) }
      <ModalCreateTeam open={myModalCreateTeam} createMyTeam={createMyTeam} />
      <ModalSocial />
    </div>
  );
}

export default Dashboard;
