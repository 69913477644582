export function IsMobile(type = 0) {
  var w = window.innerWidth;

  let zoom = window.devicePixelRatio * 100;

  // Function to apply zoom and classes
  const applyZoom = () => {
    const appContainer = document.getElementById("app-content");
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    let device = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
    const isMobile = (device || type == 1) && w < 1400;
    if (!appContainer && !isMobile) {
      return false;
    }

    if (!isMobile) {
      // Reset zoom on the container
      appContainer.style.zoom = "";
      appContainer.classList.remove("secondaryMenuPositionU5");
      appContainer.classList.remove("secondaryMenuPosition45");
      appContainer.classList.remove("zoom");
      appContainer.classList.remove("zoom-125");
      appContainer.classList.remove("zoom-150");
    }

    // Reset mobile class on the body
    document.body.classList.remove("mobile");

    if ((device || type == 1) && w < 1400) {
      // Smartphone
      document.body.style.zoom = "100%";
      document.body.classList.add("mobile");
      return true;
    }

    if (zoom > 100) {
      let diff = zoom - 100;
      let sub = 20;
      if (parseInt(zoom) == 110) {
        appContainer.classList.add("secondaryMenuPositionU5");
        appContainer.classList.add("zoom");
      }
      if (parseInt(zoom) == 125) {
        appContainer.classList.add("zoom-125");
      }
      if (parseInt(zoom) == 150) {
        sub = 30;
        appContainer.classList.add("secondaryMenuPosition45");
        appContainer.classList.add("zoom-150");
      }
      appContainer.style.zoom =
        "calc(" + zoom + "% - " + diff + "% - " + sub + "%)";
    }

    return false;
  };

  // If the DOM is already loaded, execute immediately
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    return applyZoom();
  } else {
    // Wait for DOM to be ready
    document.addEventListener("DOMContentLoaded", applyZoom);
  }
}
