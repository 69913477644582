import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import '../scss/Page.scss';

function ConfirmLega(props) {

  let history = useHistory();

  const [nameLega, setNameLega] = React.useState("");

  useEffect(() => {

    window.scrollTo(0, 0);

    let email = getHashParam("email");
    let lega = getHashParam("lega");

    if( !email ) {
      window.location.href = "/";
    }

    let value = {
      email: email,
      lega_id: lega
    }
    axios.post(process.env.REACT_APP_API_URL + '/set-lega/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            setNameLega(response.data.rls);
          }
          else {
            // NO
            localStorage.removeItem('fm_info');
            localStorage.removeItem('fm_session');
            window.top.location.href = '/';
          }
        });

  }, []);

  function getHashParam(param) {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash.split('?')[1]);
    return params.get(param);
  }

  return (
    <div className="Page">
      <div>
        <div className="content-logo">
            <img src="img/FantaUno-152.png" width="100" />
        </div>
        <div className="cover">
          <h1>Account confermato</h1>
        </div>
        <div className="content content-cookies">
          <div className="content-elms">
            <h2 className="text-center col-lg-12">Grazie!</h2>
            <p className="text-center">Adesso fai farte della lega <strong>{ nameLega }</strong></p>
          </div>
        </div>
      </div>
      <br /><br /><br />
      {/* <Footer /> */}
    </div>
  );
}

export default ConfirmLega;
