import React from 'react';
import HeaderDesktop from '../../partials/header/HeaderDesktop';
import HeaderMobile from '../../partials/header/HeaderMobile';
import '../../../scss/Header.scss';
import { IsMobile } from '../../../services/IsMobile';

function Header({title, index = 1}) {

  const [ mobile ] = React.useState(IsMobile());

  return (
    <div>
      { !mobile && (<HeaderDesktop index={index} />) }
      { mobile && (<HeaderMobile title={title} />) }
    </div>
  );
}

export default Header;
