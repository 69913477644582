import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HeaderDesktop({index}) {

  const { t, i18n } = useTranslation('common');

  const [myLevel, setMyLevel] = React.useState(2);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [myMenu, setMyMenu] = React.useState(false);

  useEffect(() => {

    if( localStorage.getItem("fm_info") ) {
      let userInfo = JSON.parse(localStorage.getItem("fm_info"));

      if( myCtrl ) {
        setMyLevel(userInfo.level);
      }

      setMyCtrl(false);
    }

  }, [myCtrl]);

  function logout(e) {
    e.preventDefault();
    localStorage.removeItem('fm_info');
    localStorage.removeItem('fm_session');
    window.location.href = "/";
  }

  // function handleClose(e) {
  //   e.preventDefault();
  //   localStorage.setItem("fm_cookie", 1);
  //   setMyCookie(false);
  // }

  function openMenu() {
    setMyMenu(!myMenu);
  }

  return (
    <div>
      <header className="HeaderDesktop">
        <div className="logo">
          <Link to="/">
            <img src="img/FantaUno-40.png" width="40" />
          </Link>
        </div>
        {localStorage.getItem('fm_session') ? (
          <div className="itemsMenu">
            <div className={index == 1 ? 'active' : ''}>
              <Link to="/dashboard">
                  Dashboard
              </Link>
              <span>|</span>
            </div>
            <div className={index == 2 ? 'active' : ''}>
              <Link to="/all-teams">
                  { t('pages.teams') }
              </Link>
              <span>|</span>
            </div>
            <div className={index == 3 ? 'active' : ''}>
              <Link to="/classification">
                { t('pages.classification') }
              </Link>
              <span>|</span>
            </div>
            <div className={index == 4 ? 'active' : ''}>
              <Link to="/calendar">
                { t('pages.results') }
              </Link>
              <span>|</span>
            </div>
            <div className={index == 5 ? 'active' : ''}>
              <Link to="/leagues">
                { t('pages.leagues') }
              </Link>
              <span>|</span>
            </div>

            <div className="hover last" onClick={(e)=> {logout(e)}}>
                Logout
            </div>
          </div>
        ) : (
          <div className="last itemsMenu">
            <Link to="/login">
                Login
            </Link>
          </div>
        )}
      </header>
    </div>
  );
}

export default HeaderDesktop;
