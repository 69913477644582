import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import '../scss/Login.scss';
import { useTranslation } from "react-i18next";
import { IsMobile } from '../services/IsMobile';


function Login() {

  let history = useHistory();

  const { t } = useTranslation('common');

  const [state, setState] = React.useState({email: "", password: "" });

  const [stateErrorEmail, setStateErrorEmail] = React.useState({errorEmail: false});

  const [stateErrorPassword, setStateErrorPassword] = React.useState({errorPassword: false});

  const [stateError, setStateError] = React.useState({error: false, errorString: ""});

  const [ mobile ] = React.useState(IsMobile());

  useEffect(() => {

    window.scrollTo(0, 0)

    if( mobile ) {
      document.body.classList = "overflow";
    }

    if( localStorage.getItem("fm_info") ) {
      let userInfo = JSON.parse(localStorage.getItem("fm_info"));
      const location = {
        pathname: '/dashboard',
        state: {
          id: userInfo.user_id,
          level: userInfo.user_level,
          page: 'home'
        }
      }
      history.push(location);
    }

  }, []);

  function sendLogin(e) {
    e.preventDefault();

    setStateErrorEmail({errorEmail: false});
    setStateErrorPassword({errorPassword: false});
    setStateError({error: false, errorString: ""});

    if( state.email && (state.password || state.password.length > 8) ) {
      let params = {
        email: state.email,
        password: state.password
      }
      axios.post(process.env.REACT_APP_API_URL + '/login/', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              localStorage.setItem("fm_session", response.data.token);
              localStorage.setItem("fm_info", JSON.stringify({
                                      "id": response.data.user_id,
                                      "level": response.data.user_level,
                                      "nickname": response.data.nickname}));

              const location = {
                pathname: '/dashboard',
                state: {
                  id: response.data.user_id,
                  level: response.data.user_level
                }
              }
              history.push(location);
            }
            else {
              // NO
              setStateError({error: true, errorString: response.data.error.rls});
            }
          });
    }
    else {
      if( !state.email ) {
        setStateErrorEmail({errorEmail: true});
      }
      if( !state.password || state.password < 8 ) {
        setStateErrorPassword({errorPassword: true});
      }
    }
  }

  return (
    <div className="Login">
      <div className="content-logo">
        <img src="img/FantaUno-152.png" width="100" />
      </div>
      <div className="content">
        <div className="card">
          <div className="card-header">
            LOGIN
          </div>
          <div className="card-body">
            <div className="content-input">
              <label htmlFor="email">Email</label>
              <input
                  type="text"
                  className="email"
                  name="email"
                  onChange={(ev) => setState({...state, email: ev.target.value})}
                  required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  style={!stateErrorEmail.errorEmail ? { marginBottom: '30px' } : { marginBottom: 0 } } />
            </div>
            <p className="error" style={!stateErrorEmail.errorEmail ? { display: 'none' } : { display: 'block' } }><small>{t('login.errorEmailOne')}</small><br /><br /></p>
            <div className="content-input">
              <label htmlFor="password">Password</label>
              <input
                    type="password"
                    className="password"
                    name="password"
                    onChange={(ev) => setState({...state, password: ev.target.value})}
                    style={!stateErrorPassword.errorPassword ? { marginBottom: '30px' } : { marginBottom: 0 } }/>
              <p className="error" style={!stateErrorPassword.errorPassword ? { display: 'none' } : { display: 'block' } }><small>{t('login.errorPasswordOne')}</small><br /><br /></p>
            </div>
            <p className="error" style={!stateError.error ? { display: 'none' } : { display: 'block' } }><small>{t('login.' + stateError.errorString)}</small><br /><br /></p>
            <div className="content-button">
              <button type="button" className="button" onClick={(e)=> {sendLogin(e)}}>
                {t('login.button')}
              </button>
            </div>
            <div className="contentLinkLogin">
                <p className="link-register">
                  <Link to="/reset">
                      {t('register.passLost')}
                  </Link>
                </p>
                <p className="link-register">
                  <Link to="/register">
                      {t('register.title')}
                  </Link>
                </p>
              </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default Login;
