import React from 'react';
import ReactDOM from 'react-dom/client';

import Login from './site/Login';
import Register from './site/Register';
import Dashboard from './site/Dashboard';
import AllTeams from './site/pages/AllTeams';
import Logout from './site/pages/Logout';
import Classification from './site/pages/Classification';
import Calendar from './site/pages/Calendar';
import Leagues from './site/pages/Leagues';

import SetPassword from './site/SetPassword';
import ConfirmLega from './site/ConfirmLega';

import ResetPassword from './site/ResetPassword';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import './scss/Dashboard.scss';

import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";

var userLang = navigator.language || navigator.userLanguage;

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: userLang,                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        it: {
            common: common_it
        },
    },
});

const Page404 = ({ location }) => {(
  window.location.href = '/'
)};

const reload = () => window.location.reload();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <I18nextProvider i18n={i18next}>
        <div>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/all-teams">
                <AllTeams />
              </Route>
              <Route exact path="/classification">
                <Classification />
              </Route>
              <Route exact path="/calendar">
                <Calendar />
              </Route>
              <Route exact path="/leagues">
                <Leagues />
              </Route>
              <Route exact path="/reset">
                <ResetPassword />
              </Route>
              <Route exact path="/set-password">
                <SetPassword />
              </Route>
              <Route exact path="/logout">
                <Logout />
              </Route>
              <Route exact path="/conferma-lega">
                <ConfirmLega />
              </Route>
              <Route component={Page404} />
              <Route path="/robots.txt" onEnter={reload} />
            </Switch>
        </div>
      </I18nextProvider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
