import React, { useEffect } from 'react';
import '../../scss/Loader.scss';
import { ReactComponent as LoaderSVG } from "../../svg/formula.svg";

function Loader({show}) {
  return (
    <div id="loader" style={!show ? { display: 'none' } : { display: 'block' } }>
      <br /><br /><br /><br /><br /><br />
      <LoaderSVG />
    </div>
  );
}

export default Loader;
