import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Header from '../partials/header/Header';
import Footer from '../partials/Footer';
import FooterMobile from '../partials/FooterMobile';
import Error from '../partials/Error';
import { useTranslation } from "react-i18next";
import { IsMobile } from '../../services/IsMobile';

import ModalCreateLega from '../partials/ModalCreateLega';
import ModalLega from '../partials/ModalLega';

import Loader from '../partials/Loader';

function Leagues() {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');

  const [myTeamState, setMyTeamState] = React.useState([]);

  const [myInfo, setMyInfo] = React.useState([]);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [myModalCreateLega, setMyModalCreateLega] = React.useState('none');

  const [appError, setStateError] = React.useState("");

  const [tabLega, setStateTabAcca] = React.useState({ one: true, two: false});

  const [allLeaugueState, setAllLeaugueState] = React.useState([]);

  const [allLeauguePublicState, setAllLeauguePublicState] = React.useState([]);

  const [editMyLega, setEditMyLegaState] = React.useState(false);

  const [myModalLega, setMyModalLega] = React.useState('none');

  const [viewLega, setViewLega] = React.useState([]);

  const [isMyLega, setIsMyLega] = React.useState(false);

  const [isPublic, setIsPublic] = React.useState(0);

  const [myStop, setMyStop] =  React.useState(true);

  const [ mobile ] = React.useState(IsMobile());
  
  const [height, setHeight] =  React.useState("");
    
  const [loader, setLoader] =  React.useState(true);

  useEffect(() => {

    window.scrollTo(0, 0);

    document.body.classList = "";

    if( mobile ) {
      var rect = document.getElementsByClassName("FooterMobile")[0].getBoundingClientRect();

      setHeight(rect.bottom)
    }

    if( !localStorage.getItem("fm_info") ) {
      window.location.href = "/";
    }

    if( myStop ) {
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        i18n.changeLanguage('it-IT');
      }
      else {
        i18n.changeLanguage('en-US');
      }
      setMyStop(false);
    }

    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    setMyInfo(userInfo);

    let params = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id
    }

    if( myCtrl ) {
      axios.post(process.env.REACT_APP_API_URL + '/dashboard/my-teams', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setMyTeamState(response.data.rls.drivers);
              // setBonus(response.data.rls.bonus);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });

      loadMyLeague(params);

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-league-public', params )
          .then(response => {
            setLoader(false);
            if(response.data.error.status === false) {
              // OK
              setAllLeauguePublicState(response.data.rls);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });
    }

    setTabs(2);
    setTabs(1);

    setMyCtrl(false);

  }, [myCtrl]);

  function onChangeMyError( error ) {
    setStateError(error);
  }

  function createMyLega(e, ctrl) {
    setEditMyLegaState([]);
    setMyModalCreateLega(ctrl);
    if( ctrl == 'block' ) {
      document.getElementsByTagName("body")[0].classList.add("overflow");
    }
    else {
      document.getElementsByTagName("body")[0].classList.remove("overflow");

      let params = {
        token: localStorage.getItem("fm_session"),
        id: myInfo.id
      }

      loadMyLeague(params);

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-league-public', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setAllLeauguePublicState(response.data.rls);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });
    }
  };

  function setTabs( index ) {
    if( (index == 1 && !tabLega.one) || (index == 2 && !tabLega.two) ) {
      setStateTabAcca({
        one: !tabLega.one,
        two: !tabLega.two
      });
    }
  }

  function editLega( league ) {
    setEditMyLegaState(league);
    setMyModalCreateLega('block');
    document.getElementsByTagName("body")[0].classList.add("overflow");
  }

  function deleteLega( league ) {
    if( window.confirm("Vuoi eliminare la Lega " + league.name + "?") ) {
      let params = {
        token: localStorage.getItem("fm_session"),
        id: myInfo.id,
        league_id: league.id
      }
      axios.post(process.env.REACT_APP_API_URL + '/dashboard/delete-league', params )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            params = {
              token: localStorage.getItem("fm_session"),
              id: myInfo.id
            }
            loadMyLeague( params );
          }
          else {
            // NO
            onChangeMyError(response.data.error.rls);
          }
          return;
        });
    }
  }

  function loadMyLeague( params ) {
    axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-league', params )
    .then(response => {
      if(response.data.error.status === false) {
        // OK
        setAllLeaugueState(response.data.rls);
      }
      else {
        // NO
        onChangeMyError(response.data.error.rls);
      }
      return;
    });
  }

  function viewModalLega( league, ctrl, isPublic = 0 ) {
    setViewLega(league);
    setIsMyLega(ctrl);
    setIsPublic(isPublic);
    setMyModalLega('block');
    document.getElementsByTagName("body")[0].classList.add("overflow");
  }

  function closeViewModalLega(e, ctrl) {
    setViewLega([]);
    setMyModalLega(ctrl);
    if( ctrl == 'block' ) {
      document.getElementsByTagName("body")[0].classList.add("overflow");
    }
    else {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
    }
  }

  return (
    <div className="App">
      <Header title={t('pages.leagues')} index="5" />
      <div className={ mobile ? 'content-card singleBox' : 'content-card singleBox forDesktop' } style={loader ? { display: 'none' } : { display: 'block' } }>
        <div className="card card-2" style={ {width: '90%', marginBottom: '50px'} }>
          <div className="card-header">
            {t('dashboard.league')}
          </div>
          <div className="card-body allUsers" style={ {maxHeight: "calc( " + height + "px - 400px)"} }>
          {t('dashboard.leagueSubtitle')}
              <div className="contentTabHeader">
                <div className={"tab " + (tabLega.one ? "active " : "")}  onClick={(e)=> {setTabs(1)}}>
                  {t('dashboard.leagueTabOne')}
                </div>
                <div className={"tab " + (tabLega.two ? "active " : "")}  onClick={(e)=> {setTabs(2)}}>
                  {t('dashboard.leagueTabTwo')}
                </div>
                <div className="contentTabBody">
                  <div className={"tabBody " + (tabLega.one ? "active " : "")}>
                  {allLeaugueState && allLeaugueState.length ? (
                            <div>
                              <div className="content-button buttonRight">
                                <button type="button" className="button" onClick={(e)=> {createMyLega(e, 'block')}}>
                                  {t('dashboard.leagueCreate')}
                                </button>
                              </div>
                              {allLeaugueState.map(function(leaugue, p) {
                                return (
                                  <div className="singleElm" key={ p }>
                                    <div className='first'><p>{ leaugue.name }</p><p className="MyCreate"><small><i>{ leaugue.user_id == myInfo.id ? 'Creata da te' : '' }</i></small></p></div> 
                                    <div><span className='fi fi-rr-eye' onClick={(e)=> {viewModalLega(leaugue, leaugue.user_id == myInfo.id)}}></span></div>
                                    <div style={leaugue.user_id == myInfo.id  ? { display: 'block' } : { display: 'none' } }><span className='fi fi-rr-pencil' onClick={(e)=> {editLega(leaugue)}}></span></div>
                                    <div style={leaugue.user_id == myInfo.id  ? { display: 'block' } : { display: 'none' } }><span className="fi fi-rr-trash" onClick={(e)=> {deleteLega(leaugue)}}></span></div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (<div>
                                  <p><i>{t('dashboard.leagueNotFound')}</i></p>
                                  <div className="content-button">
                                    <button type="button" className="button" onClick={(e)=> {createMyLega(e, 'block')}}>
                                      {t('dashboard.leagueCreateFirst')}
                                    </button>
                                  </div>
                                </div>)}
                  </div>
                  <div className={"tabBody " + (tabLega.two ? "active " : "")}>
                  {allLeauguePublicState && allLeauguePublicState.length ? (
                            <div>
                              {allLeauguePublicState.map(function(leaugue, p) {
                                return (
                                  <div className="singleElm" key={ p }>
                                    <div className='first'>{ leaugue.name }</div> 
                                    <div><span className='fi fi-rr-eye' onClick={(e)=> {viewModalLega(leaugue, false, 1)}}></span></div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (<div>
                                  <p><i>{t('dashboard.leagueNotFoundPublic')}</i></p>
                                </div>)}
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <Loader show={loader} />
      <Error params={appError} onChangeError={onChangeMyError} />
      { !mobile && (<Footer />) }
      { mobile && (<FooterMobile index="5" />) }
      <ModalCreateLega open={myModalCreateLega} createMyLega={createMyLega} edit={editMyLega} />
      <ModalLega open={myModalLega} close={closeViewModalLega} lega={viewLega} isMyLega={isMyLega} isPublic={isPublic} />
    </div>
  );
}

export default Leagues;
