import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Header from '../partials/header/Header';
import Footer from '../partials/Footer';
import FooterMobile from '../partials/FooterMobile';
import Error from '../partials/Error';
import { useTranslation } from "react-i18next";
import { IsMobile } from '../../services/IsMobile';

import Loader from '../partials/Loader';

function Classification() {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');

  const [myInfo, setMyInfo] = React.useState([]);

  const [myTeamState, setMyTeamState] = React.useState([]);

  const [allDriversState, setAllDriversState] = React.useState([]);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [appError, setStateError] = React.useState("");

  const [myStop, setMyStop] =  React.useState(true);
  
  const [loader, setLoader] =  React.useState(true);

  const [ mobile ] = React.useState(IsMobile());
  
  const [height, setHeight] =  React.useState("");

  useEffect(() => {

    window.scrollTo(0, 0);

    document.body.classList = "";

    if( mobile ) {

      var rect = document.getElementsByClassName("FooterMobile")[0].getBoundingClientRect();
  
      setHeight(rect.bottom)

    }

    if( !localStorage.getItem("fm_info") ) {
      window.location.href = "/";
    }

    if( myStop ) {
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        i18n.changeLanguage('it-IT');
      }
      else {
        i18n.changeLanguage('en-US');
      }
      setMyStop(false);
    }

    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    setMyInfo(userInfo);

    let params = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id
    }

    if( myCtrl ) {

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/my-teams', params )
      .then(response => {
        setLoader(false);
        if(response.data.error.status === false) {
          // OK
          setMyTeamState(response.data.rls.drivers);
          // setBonus(response.data.rls.bonus);
        }
        else {
          // NO
          onChangeMyError(response.data.error.rls);
        }
        return;
      });

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-points', params )
      .then(response => {
        if(response.data.error.status === false) {
          // OK
          setAllDriversState(response.data.rls.drivers);
        }
        else {
          // NO
          onChangeMyError(response.data.error.rls);
        }
        return;
      });

    }

    setMyCtrl(false);

  }, [myCtrl]);

  function onChangeMyError( error ) {
    setStateError(error);
  }

  return (
    <div className="App">

      <Header title={t('pages.classification')} index="3" />

      <div className={ mobile ? 'content-card' : 'content-card forDesktop' } style={loader ? { display: 'none' } : { display: 'block' } }>
        <div className="card card-2" style={ {width: '90%', marginBottom: '50px'} }>
          <div className="card-header">
              {t('dashboard.cardFour')}
          </div>
          {myTeamState && myTeamState.length > 0 ? (
              <div className="card-body allPoints" style={ {maxHeight: "calc( " + height + "px - 400px)", padding: 0} }>
                <div className="contentDriverTable">
                  <div className="firstCol">
                    <div className="rowSingleRace">
                      <div className="rowSingleRaceOne">#</div>
                      <div className="rowSingleRaceTwo"></div>
                      <div className="rowSingleRaceThree">{t('dashboard.piloti')}</div>
                      <div className="rowSingleRaceFour">{t('dashboard.punti')}</div>
                    </div>
                    {allDriversState && allDriversState.length ? (
                      allDriversState.map(function(driver, k) {
                        if( k < allDriversState.length/2) {
                          return (
                            <div className="rowSingleRace" key={ k }>
                              <div className="rowSingleRaceOne">{ k+1 }</div>
                              <div className="rowSingleRaceTwo"><img src={ driver.link } width="50" style={{color: driver.color}} /></div>
                              <div className="rowSingleRaceThree driverSingleRace"><strong>{driver.name} {driver.surname}</strong></div>
                              <div className="rowSingleRaceFour points">{driver.points ? driver.points : 0}pt</div>
                            </div>
                          );
                        }
                      })) : (<div>{t('dashboard.noResult')}</div>)}
                  </div>
                  <div className="secondCol">
                    <div className="rowSingleRace hideIsMobile">
                      <div>#</div>
                      <div></div>
                      <div>{t('dashboard.piloti')}</div>
                      <div>{t('dashboard.punti')}</div>
                    </div>
                    {allDriversState && allDriversState.length ? (
                      allDriversState.map(function(driver, k) {
                        if( k > allDriversState.length/2) {
                          return (
                            <div className="rowSingleRace" key={ k }>
                              <div className="rowSingleRaceOne">{ k+1 }</div>
                              <div className="rowSingleRaceTwo"><img src={ driver.link } width="50" style={{color: driver.color}} /></div>
                              <div className="rowSingleRaceThree driverSingleRace"><strong>{driver.name} {driver.surname}</strong></div>
                              <div className="rowSingleRaceFour points">{driver.points ? driver.points : 0}pt</div>
                            </div>
                          );
                        }
                      })) : (<div>{t('dashboard.noResult')}</div>)}
                  </div>
                </div>
              </div>
            ) : (<div className="noSetTeam">{t('dashboard.noSetTeam')}</div>)}
        </div>
      </div>
      <Loader show={loader} />
      <Error params={appError} onChangeError={onChangeMyError} />
      { !mobile && (<Footer />) }
      { mobile && (<FooterMobile index="3" />) }
    </div>
  );
}

export default Classification;
