import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Header from '../partials/header/Header';
import Footer from '../partials/Footer';
import FooterMobile from '../partials/FooterMobile';
import { useTranslation } from "react-i18next";
import { IsMobile } from '../../services/IsMobile';

function Leagues() {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [myStop, setMyStop] =  React.useState(true);

  const [ mobile ] = React.useState(IsMobile());
  
  const [height, setHeight] =  React.useState("");

  useEffect(() => {

    window.scrollTo(0, 0);

    document.body.classList = "";

    if( mobile ) {
      var rect = document.getElementsByClassName("FooterMobile")[0].getBoundingClientRect();

      setHeight(rect.bottom)
    }

    if( !localStorage.getItem("fm_info") ) {
      window.location.href = "/";
    }

    if( myStop ) {
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        i18n.changeLanguage('it-IT');
      }
      else {
        i18n.changeLanguage('en-US');
      }
      setMyStop(false);
    }

    setMyCtrl(false);

  }, [myCtrl]);

  function logout(e) {
    e.preventDefault();
    localStorage.removeItem('fm_info');
    localStorage.removeItem('fm_session');
    window.location.href = "/";
  }

  return (
    <div className="App">
      <Header title={t('pages.logout')} index="6" />
      <div className={ mobile ? 'content-card' : 'content-card forDesktop' } style={{ position: 'relative', top: '100px'}}>
        <div className="card card-2">
          <div className="card-header">
            {t('dashboard.logout')}
          </div>
          <div className="card-body allUsers" style={ {maxHeight: "calc( " + height + "px - 400px)"} }>
            <br /><br />
            <div className="content-button">
              <button type="button" className="button" onClick={(e)=> {logout(e)}}>
                {t('dashboard.exit')}
              </button>
            </div>
          </div>
        </div>
      </div>
      { !mobile && (<Footer />) }
      { mobile && (<FooterMobile index="6" />) }
    </div>
  );
}

export default Leagues;
