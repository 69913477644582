import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Header from '../partials/header/Header';
import Footer from '../partials/Footer';
import FooterMobile from '../partials/FooterMobile';
import Error from '../partials/Error';
import { useTranslation } from "react-i18next";
import { IsMobile } from '../../services/IsMobile';

function Calendar() {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');

  const [myInfo, setMyInfo] = React.useState([]);

  const [myTeamState, setMyTeamState] = React.useState([]);

  const [allRacesState, setAllRacesState] = React.useState([]);

  const [allDriversState, setAllDriversState] = React.useState([]);

  const [allActionsState, setAllActionsState] = React.useState([]);

  const [yearState, setYearState] = React.useState(2022);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [appError, setStateError] = React.useState("");

  const [viewAllRaces, setViewAllRaces] = React.useState(false);

  const [myStop, setMyStop] =  React.useState(true);

  const [ mobile ] = React.useState(IsMobile());
    
  const [height, setHeight] =  React.useState("");

  useEffect(() => {

    window.scrollTo(0, 0);

    document.body.classList = "";

    if( mobile ) {
      var rect = document.getElementsByClassName("FooterMobile")[0].getBoundingClientRect();

      setHeight(rect.bottom)
    }

    if( !localStorage.getItem("fm_info") ) {
      window.location.href = "/";
    }

    if( myStop ) {
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        i18n.changeLanguage('it-IT');
      }
      else {
        i18n.changeLanguage('en-US');
      }
      setMyStop(false);
    }

    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    setMyInfo(userInfo);

    let params = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id
    }

    if( myCtrl ) {
      axios.post(process.env.REACT_APP_API_URL + '/dashboard/my-teams', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setMyTeamState(response.data.rls.drivers);
              // setBonus(response.data.rls.bonus);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-points', params )
      .then(response => {
        if(response.data.error.status === false) {
          // OK
          setAllRacesState(response.data.rls.races);
          setAllDriversState(response.data.rls.drivers);
          setAllActionsState(response.data.rls.actions);
          setYearState(response.data.rls.year);
        }
        else {
          // NO
          onChangeMyError(response.data.error.rls);
        }
        return;
      });

    }

    setMyCtrl(false);

  }, [myCtrl]);

  function onChangeMyError( error ) {
    setStateError(error);
  }

  function ctrlPoint( race_id ) {
    allDriversState.map(function(driver, i) {
      let sumPoint = 0;
      allActionsState.map(function(action, j) {
          let userInfo = JSON.parse(localStorage.getItem("fm_info"));
          let params = {
            token: localStorage.getItem("fm_session"),
            id: userInfo.id,
            race_id: race_id,
            driver_id: driver.id,
            action_id: action.id
          }
          axios.post(process.env.REACT_APP_API_URL + '/dashboard/single-point', params )
            .then(response => {
              if(response.data.error.status === false) {
                // OK
                if( response.data.rls.point ) {
                  document.getElementById('pointSingleRace-' + race_id + '-' + driver.id + '-' + action.id).classList.add("active");
                  sumPoint = sumPoint + parseInt(action.point);
                  document.getElementById('pointSingleRace-' + race_id + '-' + driver.id + '-0').innerHTML = sumPoint + 'pt';
                }
              }
              else {
                // NO
                onChangeMyError(response.data.error.rls);
              }
              return;
            });
      });
    });
    return 0;
  };

  return (
    <div className="App">
      <Header title={t('pages.results')} index="4" />
      <div className={ mobile ? 'content-card' : 'content-card forDesktop' }>
        <div className="card card-2" style={ {width: '90%', marginBottom: '50px'} }>
          <div className="card-header">
            {t('dashboard.cardThree')}
          </div>
          <div className="card-body allPoints" style={ {maxHeight: "calc( " + height + "px - 400px)"} }>
            {allRacesState && allRacesState.length ? (
                allRacesState.map(function(item, i) {
                  return (
                    <div className="" key={ i }>
                      <div
                        id={"headers-tab-" + i}
                        className={"headers-tab"}
                        onClick={() => {
                            document.getElementById('headers-tab-' + i).classList.toggle("active");
                            document.getElementById('contents-tab-' + i).classList.toggle("active");
                            if( document.getElementById('headers-tab-' + i).classList.contains("active") ) {
                              ctrlPoint( item.id );
                            }
                          }}>
                            <div><img src={ item.flag } width="50" /></div>
                            <div className="nameRace">&nbsp;&nbsp;{ item.race } { yearState }</div>
                            <div><span className='fi fi-rr-angle-down icon'></span></div>
                      </div>
                      <div id={"contents-tab-" + i} className="contents-tab">
                        {allDriversState.map(function(driver, j) {
                            return (
                              <div key={ j } className="contentSingleDriverTable">
                                <div className="contentDriverTable displayFlex">
                                  <div className="contentDriverTableImg">
                                    <div className="imgCenter">
                                      <img src={ driver.link } width="50" style={{color: driver.color}} />
                                    </div>
                                  </div>
                                  <div>{driver.name} {driver.surname}</div>
                                  <div className="contentDriverTablePoints" id={"pointSingleRace-" + item.id + '-' + driver.id + '-0'}>0pt</div>
                                </div>
                                <div>
                                  <hr className="borderRed" />
                                </div>
                                <div>
                                {allActionsState.map(function(action, k) {
                                  return (
                                    <div key={ k } className="rowAction" id={"pointSingleRace-" + item.id + '-' + driver.id + '-' + action.id}>
                                      <div className="contentDriverTable displayFlex">
                                        <div>{t('actions.' + action.key_translate)}</div>
                                        <div className="pointSingleRace">{action.point}pt</div>
                                      </div>
                                      <hr />
                                    </div>
                                  )})}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>{t('dashboard.noResult')}</div>
              )}
          </div>
        </div>
      </div>
      <Error params={appError} onChangeError={onChangeMyError} />
      { !mobile && (<Footer />) }
      { mobile && (<FooterMobile index="4" />) }
    </div>
  );
}

export default Calendar;
