import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

function HeaderMobile({title}) {

  const [myLevel, setMyLevel] = React.useState(2);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [myMenu, setMyMenu] = React.useState(false);

  useEffect(() => {

    if( localStorage.getItem("fm_info") ) {
      let userInfo = JSON.parse(localStorage.getItem("fm_info"));

      if( myCtrl ) {
        setMyLevel(userInfo.level);
      }

      setMyCtrl(false);
    }

  }, [myCtrl]);

  function openMenu() {
    setMyMenu(!myMenu);
  }

  return (
    <div>
      <header className="HeaderMobile">
        <div className="logo">
          <Link to="/">
            <img src="img/FantaUno-40.png" width="40" />
          </Link>
          <h1>{ title }</h1>
        </div>
      </header>
    </div>
  );
}

export default HeaderMobile;
